<template>
  <div class="pie-wrapper">
    <v-row class="pa-0 mt-6" style="border-bottom:none!important">
      <v-col class="pa-0" cols="4">
        <span style="font-weight:600">浏览器类型</span>
        <div
          class="echart"
          id="browser-echart"
          :style="{ float: 'left', width: '100%', height: '300px' }"
        ></div>
      </v-col>
      <v-col class="pa-0" cols="4">
        <span style="font-weight:600">操作系统</span>
        <div
          class="echart"
          id="system-echart"
          :style="{ float: 'left', width: '100%', height: '300px' }"
        ></div>
      </v-col>
      <v-col class="pa-0" cols="4" v-if="0">
        <span style="font-weight:600">PC/移动</span>
        <div
          class="echart"
          id="pcmobile-echart"
          :style="{ float: 'left', width: '100%', height: '300px' }"
        ></div>
      </v-col>
      <v-col class="pa-0" cols="4">
        <span style="font-weight:600">用户组分布</span>
        <div
          class="echart"
          id="locationsNum-echart"
          :style="{ float: 'left', width: '100%', height: '300px' }"
        ></div>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-12">
      <v-col class="pa-0" cols="4">
        <span style="font-weight:600">登录方式</span>
        <div
          class="echart"
          id="loginmethod-echart"
          :style="{ float: 'left', width: '100%', height: '300px' }"
        ></div>
      </v-col>
      <v-col class="py-0 pr-6 " cols="4">
        <span style="font-weight:600">用户位置</span>
        <div
          class="mt-4"
          id="login-location"
          :style="{ float: 'left', width: '100%' }"
        >
          <v-data-table
            :headers="location_headers"
            :items="locations"
            class="elevation-0"
            :hide-default-footer="true"
            :disable-sort="true"
            :fixed-header="true"
            no-data-text="暂无数据"
          >
            <template
              v-slot:[`item.value`]="{ item }"
              v-if="locations.length > 0"
            >
              <v-chip :color="getColor(locationsNum, locations, item.value)">
                {{ item.value }}
              </v-chip>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col class="pa-0" cols="4">
        <span style="font-weight:600">应用访问排名</span>
        <div
          class="mt-4"
          id="application-ranck"
          :style="{ float: 'left', width: '100%' }"
        >
          <v-data-table
            :headers="application_headers"
            :items="applications"
            class="elevation-0"
            :hide-default-footer="true"
            :disable-sort="true"
            :fixed-header="true"
            no-data-text="暂无数据"
          >
            <template
              v-slot:[`item.value`]="{ item }"
              v-if="locations.length > 0"
            >
              <v-chip
                :color="getColor(applicationsNum, applications, item.value)"
              >
                {{ item.value }}
              </v-chip>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { pieEchart } from "../util/echart";
export default {
  name: "PieEchart",
  props: ["breakdownData"],
  data() {
    return {
      browserName: "浏览器类型",
      browsers: [],
      systemName: "操作系统",
      os_types: [],
      pcmobileName: "PC/移动",
      device_type: [],
      loginnumName: "登录次数",
      groups: [],
      loginmethodName: "登录方式",
      sources: [],
      applications: [],
      locations: [],
      location_headers: [
        {
          text: "位置",
          value: "name"
        },
        {
          text: "次数",
          value: "value"
        }
      ],
      application_headers: [
        {
          text: "应用",
          value: "name"
        },
        {
          text: "次数",
          value: "value"
        }
      ]
    };
  },
  methods: {
    refreshpies() {
      let browser_echart = document.getElementById("browser-echart");
      let system_echart = document.getElementById("system-echart");
      // let pcmobile_echart = document.getElementById("pcmobile-echart");
      let loginnum_echart = document.getElementById("locationsNum-echart");
      let loginmethod_echart = document.getElementById("loginmethod-echart");
      this.browserinitChart(browser_echart, this.browserName, this.browsers);
      this.systeminitChart(system_echart, this.systemName, this.os_types);
      // this.pcmobileinitChart(
      //   pcmobile_echart,
      //   this.pcmobileName,
      //   this.device_type
      // );
      this.loginnuminitChart(loginnum_echart, this.loginnumName, this.groups);
      this.loginmethodinitChart(
        loginmethod_echart,
        this.loginmethodName,
        this.sources
      );
    },
    get_breakdown() {
      let {
        applications,
        browsers,
        device_type,
        groups,
        locations,
        os_types,
        sources
      } = this.breakdownData;
      this.applications = applications;
      this.browsers = browsers;
      this.device_type = device_type;
      this.groups = groups;
      this.locations = locations;
      this.os_types = os_types;
      this.sources = sources;
    },
    browserinitChart(id, name, pieData) {
      this.renderInitEchart(id, name, pieData);
    },
    systeminitChart(id, name, pieData) {
      this.renderInitEchart(id, name, pieData);
    },
    pcmobileinitChart(id, name, pieData) {
      this.renderInitEchart(id, name, pieData);
    },
    loginnuminitChart(id, name, pieData) {
      this.renderInitEchart(id, name, pieData);
    },
    loginmethodinitChart(id, name, pieData) {
      this.renderInitEchart(id, name, pieData);
    },
    renderInitEchart(id, name, pieData) {
      let total = 0;
      if (pieData.length > 0) {
        for (let item of pieData) {
          total += item.value;
        }
      }

      let getchart = echarts.init(id);
      let option = pieEchart(name, pieData, total);
      getchart.setOption(option);

      getchart.on("mouseover", function() {
        getchart.setOption({
          title: {
            text: ""
          }
        });
      });

      getchart.on("mouseout", function() {
        getchart.setOption({
          title: {
            text: ["{value|" + total + "}"]
          }
        });
      });

      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    },
    getColor(statistic_num, curdata, num) {
      let curdataLen = curdata.length;
      let avg = num / (statistic_num / curdataLen) / 2;

      return `rgba(0,118,196,${avg})`;
    }
  },
  computed: {
    locationsNum() {
      let s = 0;
      for (let item of this.locations) {
        s += item.value;
      }
      return s;
    },
    applicationsNum() {
      let s = 0;
      for (let item of this.applications) {
        s += item.value;
      }
      return s;
    }
  },
  watch: {
    breakdownData: {
      handler() {
        this.get_breakdown();
        this.refreshpies();
      },
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.pie-wrapper {
  width: 100%;
}

.v-chip.v-size--default {
  width: 70px;
  height: 37px;
  border-radius: 0;
}

/deep/ .v-data-table__wrapper {
  height: 280px;
  overflow-y: auto;
}

/deep/ .v-data-table td {
  height: 40px !important;
}

/deep/
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

/deep/ .theme--light.v-data-table .v-data-table__empty-wrapper {
  color: rgba(0, 0, 0, 0.6);
}

/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px;
}

/deep/ .v-chip {
  padding: 0 6px;
}

/deep/ .theme--light.v-data-table.v-data-table--fixed-header thead th {
  background-color: #f2f2f2;
}

/deep/ .v-data-table__wrapper > table > thead > tr > th:last-child {
  width: 160px;
}
</style>
