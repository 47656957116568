<template>
  <v-app light>
    <v-main>
      <v-container py-6>
        <v-responsive class="text-center mx-auto" style="max-width: 700px;">
          <h2 v-if="identity">
            name={{ identity.name }}, role={{ identity.claims["role"] }}
          </h2>
          <h2 v-else>not logged in</h2>
        </v-responsive>
        <v-card class="mx-auto pa-4" style="max-width: 600px;">
          <v-tabs align-with-title v-model="tabs" class="my-4">
            <v-tab v-for="source in credential_sources" :key="source.name">{{
              source.name
            }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item v-for="source in credential_sources" :key="source.name">
              <v-card class="pa-4">
                <v-form @submit.prevent="FinishLogin(source)">
                  <v-text-field
                    v-for="field in source.fields"
                    outlined
                    :label="$t(field)"
                    :key="field"
                    :type="
                      field === 'password' && !show_password
                        ? 'password'
                        : 'text'
                    "
                    v-model="credentials[source.id][field]"
                  >
                    <v-icon
                      v-if="field === 'password'"
                      slot="append"
                      @click="() => (show_password = !show_password)"
                    >
                      {{ show_password ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-text-field>
                  <v-btn light color="secondary" x-large block type="submit"
                    >Submit</v-btn
                  >
                </v-form>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <div class="my-4 d-flex" v-if="browser_sources.length">
            <v-divider class="my-2"></v-divider>
            <div
              class="text-overline mx-1"
              style="line-height: 1rem; font-weight: bold; color: #666"
            >
              或使用第三方登录
            </div>
            <v-divider class="my-2"></v-divider>
          </div>
          <div
            class="d-flex justify-space-between"
            v-if="browser_sources.length"
          >
            <v-tooltip bottom v-for="item in browser_sources" :key="item.link">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="flex-grow-1"
                  outlined
                  depressed
                  :key="item.name"
                  v-bind:href="item.link"
                  target="_self"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-img
                    :src="require('../assets/sources/' + item.type + '.png')"
                    contain
                    max-width="25px"
                  ></v-img>
                </v-btn>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { api_request } from "@/util/network";
import { encryptPassword } from "@/util/jwes";

export default {
  name: "Login",
  components: {},

  data: function() {
    return {
      tabs: null,
      right: null,
      show_password: false,
      browser_sources: [],
      credential_sources: [],
      password_key: null,
      password_challenge: null
    };
  },
  computed: {
    // separate source for credentials
    credentials() {
      let obj = {};
      for (const source of this.credential_sources) {
        obj[source.id] = {};
      }

      return obj;
    },
    ...mapState({
      identity: state => state.identity
    })
  },
  methods: {
    async FinishLogin({ id }) {
      let { challenge, exchange_key } = await this.$http
        .get(`api/source/${id}/start`)
        .delegateTo(api_request);

      let credentials = Object.assign({}, this.credentials[id]);
      if (credentials["password"] !== undefined) {
        credentials["password"] = encryptPassword(
          exchange_key,
          challenge,
          credentials["password"]
        );
        this.$http
          .post(`api/source/${id}/finish`, credentials)
          .delegateTo(api_request)
          .then(data => {
            this.$store.commit("auth_success", data);
            if (data["return_url"]) {
              window.location = data["return_url"];
            } else {
              this.$router.push({ path: "/" });
            }
            return "登录成功";
          })
          .catch(({ code, message }) => {
            throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    }
  },
  created: function() {
    return this.$http
      .get(`api/source/login_methods`)
      .delegateTo(api_request)
      .then(sources => {
        this.browser_sources = sources.external;
        this.credential_sources = sources.credential;

        if (
          this.credential_sources.length === 0 &&
          this.browser_sources.length === 1
        ) {
          location.href = this.browser_sources[0].link;
        }
      });
  }
};
</script>
