<template>
  <div class="SourceList">
    <Breadcrumbs class="main-top">
      <template slot="action-buttons">
        <v-btn depressed color="primary" to="/source/create">
          {{ $t("source.Create Identity Source") }}
        </v-btn>
      </template>
    </Breadcrumbs>
    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      <span>身份源</span><br />
      身份源是企业或单位的用户数据库，其提供的信息具有权威性，此类渠道来源的用户被视为本地用户。常见的身份源有LDAP、企业微信、钉钉、飞书等。
    </v-alert>
    <v-simple-table class="table-wrapper">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left pl-4">
              ID
            </th>
            <th class="text-left">
              {{ $t("Identity Name") }}
            </th>
            <th class="text-left">
              {{ $t("Identity Adapter") }}
            </th>
            <th class="text-left">
              {{ $t("Enabled") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="sources.length > 0">
          <tr v-for="item in sources" :key="item.id" class="text-left">
            <td class="pl-4">{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="item.enabled"
                :loading="item.loading"
                @click.stop="change(item)"
              ></v-switch>
            </td>
            <td class="text-center">
              <!-- <v-btn
                icon
                elevation="0"
                :disabled="item.enabled"
                @click.stop="show_sheet(item)"
              > -->
              <v-btn icon @click.stop="edit(item.id)">
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
              <v-btn icon elevation="0" @click.stop="show_info(item)">
                <v-icon>
                  mdi-text-box-search-outline
                </v-icon>
              </v-btn>
              <v-btn icon elevation="0" @click.stop="show_log(item)">
                <v-icon>
                  mdi-math-log
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                color="red"
                :disabled="item.enabled"
                @click.stop="deleteSource(item)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <Component
      :is="sheet_editor"
      :source_id="current_id"
      :is_readonly="this.readonly"
      :allDatas="allDatas"
    />
    <ConfirmBox ref="confirm" />
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import CasRestAPI from "./editor/CasRestAPI";
import CasEditor from "@/views/source/editor/Cas";
import LdapEditor from "@/views/source/editor/Ldap";
import FNU_LdapEditor from "@/views/source/editor/FNU_Ldap";
import Breadcrumbs from "@/components/Breadcrumbs";
import Oauth2Editor from "@/views/source/editor/Oauth2";
import ConfirmBox from "@/components/ConfirmBox";
import HttpBasicEditor from "@/views/source/editor/HttpBasic";
import DingTalk from "@/views/source/editor/DingTalk";
import LarkEditor from "@/views/source/editor/Lark";
import RadiusEditor from "@/views/source/editor/Radius";
import WechatEnterpriseEditor from "@/views/source/editor/WechatEnterprise";

const EDITORS = {
  CAS_REST_API: CasRestAPI,
  CAS: CasEditor,
  LDAP: LdapEditor,
  FNU_LDAP: FNU_LdapEditor,
  OAUTH2: Oauth2Editor,
  DING_TALK: DingTalk,
  LARK: LarkEditor,
  HTTP_BASIC: HttpBasicEditor,
  WECHAT_ENTERPRISE: WechatEnterpriseEditor,
  RADIUS: RadiusEditor
};

export default {
  name: "SourceList",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      sheet_editor: null,
      current_id: null,
      show_confirm: null,
      readonly: null,
      sources: [],
      allDatas: {}
    };
  },
  created() {
    this.refreshList();
    bus.$off("refresh").$on("refresh", () => {
      this.onPageChange(this.curPage, 10);
    });
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  methods: {
    edit(id) {
      this.$router.push({ path: `/source/view/${id}` });
    },
    onPageChange(curPage, limit) {
      this.refreshList(curPage, limit);
    },
    show_info(item) {
      this.getCurIdData(item.id);
      this.sheet_editor = EDITORS[item.type];
      this.current_id = item.id;
      this.readonly = true;
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    show_log(item) {
      this.$router.push({
        path: "/audit/login",
        query: {
          id: item.id
        }
      });
    },
    deleteSource({ id, name }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除企业身份源？ #${id} ${name}`,
          callback: () => {
            return this.$http
              .post(`api/source/${id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.curPage =
                  this.curPage > Math.ceil((this.totalPage - 1) / 10)
                    ? this.curPage - 1
                    : this.curPage;
                if (this.totalPage - 1 > 0) {
                  this.onPageChange(this.curPage, 10);
                } else {
                  this.refreshList();
                }
                return "企业身份源已成功删除";
              })
              .catch(({ code, message }) => {
                this.onPageChange(this.curPage, 10);
                throw `企业身份源删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    change(item) {
      const index = this.sources.findIndex(_item => {
        return item.id === _item.id;
      });

      item.loading = true;
      const operationText = item.enabled ? "开启" : "关闭";
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要${operationText}企业身份源 ${item.name}？`,
          callback: function() {
            return this.$http
              .post(`api/source/${item.id}/enable`, {
                enable: item.enabled
              })
              .delegateTo(api_request)
              .then(() => {
                return `企业身份源 ${item.name} 已成功${operationText}`;
              })
              .catch(({ code, message }) => {
                throw `企业身份源 ${item.name} ${operationText}失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          item.enabled = !item.enabled;
        })
        .finally(() => {
          this.onPageChange(this.curPage, 10);
          item.loading = false;
          this.$set(this.sources, index, this.sources[index]);
        });
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(`api/source/list?offset=${(curPage - 1) * limit}&limit=${limit}`)
        .delegateTo(api_request)
        .then(res => {
          this.totalPage = res.total;
          this.sources = res.sources;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    getCurIdData(id) {
      this.$http
        .get(`api/source/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.allDatas = data;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox,
    CasEditor,
    DingTalk,
    LdapEditor,
    FNU_LdapEditor,
    LarkEditor,
    Oauth2Editor,
    HttpBasicEditor,
    WechatEnterpriseEditor,
    RadiusEditor
  }
};
</script>

<style lang="less" scoped>
.SourceList {
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    margin-top: 20px;
    width: 96%;
  }
  .v-alert {
    /deep/ .v-alert__content {
      color: #213142;
    }
    /deep/ .v-alert__icon {
      font-size: 2rem !important;
      height: 3rem;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
