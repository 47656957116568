import Vue from "vue";
import VueI18n from "vue-i18n";

// Ready translated locale messages
const messages = {
  zhHans: {
    username: "用户名",
    login_name: "用户名",
    password: "密码",
    verify_code: "验证码",
    Actions: "操作",
    Enabled: "状态",
    Icon: "图标",
    Description: "描述",
    Cancel: "取消",
    Confirm: "确认",
    Confirmation: "操作确认",
    "Are you sure?": "您确认要执行此操作吗？",
    "Operation completed.": "操作成功。",
    "Operation Failed.": "操作失败。",

    menu: {
      Authentication: "认证",
      "Identity Source": "身份源管理",
      "Custom Login": "自定义登录",
      "Authentication Method": "认证方式管理",
      "Multi-Factor Login": "多因子认证",
      "User Directory": "用户管理",
      Departments: "组织机构",
      Groups: "用户组管理",
      Users: "用户管理",
      Attribute: "属性管理",
      Application: "应用管理",
      "Application List": "应用列表",
      "Create Application": "创建应用",
      Audit: "审计日志",
      "Login Log": "登录日志",
      "Application Log": "应用访问日志",
      "UserAccess Log": "用户中心访问日志",
      Authorization: "权限管理",
      Setting: "系统设置",
      "Personalization Settings": "个性化设置",
      "Security Settings": "安全设置",
      "Log Settings": "日志设置",
      License: "授权管理",
      Home: "首页"
    },
    "Identity Adapter": "身份适配器",
    "Authentication Adapter": "认证适配器",
    "Application Adapter": "应用适配器",
    "SubApplication Adapter": "子账户适配器",
    "Identity Name": "身份源名称",
    "Authentication Name": "认证方式名称",
    "CustomLogin ID": "登录ID",
    "CustomLogin Name": "名称",
    "CustomLogin Url": "登录地址",
    "Application Name": "应用名称",
    "SubApplication Name": "子账户名称",
    "Account ID": "主账户ID",
    "Login Name": "登录名",
    "SubApplication Description": "子账户描述",
    "ID Info": "3-20个字符可以包含数字和大小写字母，如不填写，将由系统自动生成",

    source: {
      "Edit {type} adapter config": "编辑{type}身份源",
      "Edit {type} account config": "编辑{type}账户同步选项",
      "Edit {type} department config": "编辑{type}组织同步选项",
      "Edit {type} sync config": "编辑{type}同步配置",
      "See {type} adapter config": "查看{type}身份源",
      "Create {type} adapter config": "创建{type}身份源",
      "Create Identity Source": "添加身份源",
      "Create Authentication Method": "添加认证方式"
    },
    customLogin: {
      "Edit sync config": "编辑自定义登录",
      "See adapter config": "查看自定义登录",
      "Create adapter config": "创建自定义登录",
      "Create CustomLogin": "添加自定义登录",
      label: {
        external_id: "自定义登录ID",
        name: "名称"
      }
    },
    method: {
      "Edit {type} adapter config": "编辑{type}认证方式",
      "See {type} adapter config": "查看{type}认证方式",
      "Create {type} adapter config": "启用{type}认证方式",
      "Create Identity Source": "添加认证方式",
      "Create Authentication Method": "添加认证方式"
    },
    account: {
      "Create Account": "新建用户",
      "Create EXTRAL": "新建扩展属性",
      "Import Account": "导入用户",
      "Export Account": "导出用户",
      "Edit Account": "编辑用户",
      "Edit EXTRAL": "编辑扩展属性",
      external_id: "原系统用户ID",
      login_name: "登录名",
      nickname: "昵称",
      fullname: "姓名",
      mobilephone: "手机号",
      emailaddress: "邮箱",
      Search: "搜索",
      "Search Available Group": "搜索可用组",
      "Search Existing Group": "搜索已添加的组",
      "Add Group": "添加组",
      path: "目录路径",
      // "Do Add Groups": "确认添加",
      department: {
        "Department Name": "组织名称",
        "Department Description": "组织描述",
        "Department Path": "组织目录路径"
      },
      group: {
        "Group Name": "用户组名称",
        "Group Description": "用户组描述",
        "Group Path": "用户组目录路径"
      },
      external: {
        "External Source Name": "外部用户来源名称",
        "External Name": "外部用户名称",
        "External Created": "外部用户创建时间"
      },
      label: {
        parent_directory: "部门名称",
        fullname: "姓名",
        login_name: "账户登录名",
        preferred_username: "账户名称",
        nickname: "昵称",
        password: "密码",
        old_password: "原密码",
        new_password: "新密码",
        confirm_password: "确认密码",
        email: "邮箱地址",
        mobile: "手机号",
        external_id: "外部ID",
        expiry_time: "过期时间",
        role: "角色"
      },
      placeholder: {
        fullname: "姓名",
        login_name: "账户登录名(账户名称)",
        preferred_username: "账户名称",
        nickname: "昵称",
        password: "密码",
        old_password: "原密码",
        new_password: "新密码",
        confirm_password: "确认密码",
        email: "请输入有效的邮箱地址",
        mobile: "请输入有效的手机号",
        external_id: "外部ID",
        expiry_time: "过期时间",
        role: "角色"
      },
      hint: {
        fullname: "用户姓名",
        preferred_username: "账户名称",
        login_name:
          "登录账号不能以特殊字符开始，可包含大写字母、小写字母、数字、中划线(-)、下划线(_)、点(.)、长度至少 4 位",
        nickname: "用户昵称",
        emailaddress: "邮箱",
        mobilephone: "手机号",
        old_password: "请输入原密码",
        password:
          "密码至少包含大小写字母+数字+特殊字符; 长度至少8位，最多20位。",
        confirm_password: "请再次输入新密码",
        email: "手机号或邮箱至少填写一个。",
        mobile: "手机号或邮箱至少填写一个。",
        external_id:
          "IAM中唯一身份标识，一般应填写原系统用户ID, 若不填将由系统自动生成。",
        expiry_time: "不填将使用系统默认过期时间 2222-12-31",
        role: "角色"
      }
    },
    group: {
      "Create Group": "新建用户组",
      external_id: "原系统用户组ID",
      name: "名称",
      path: "路径",
      description: "描述",
      Search: "搜索",
      "Edit Group": "编辑用户组",
      "See Group": "查看用户组",
      "Search Available Account": "搜索用户",
      "Search Existing Account": "搜索已添加的用户",
      "Add Account": "添加用户",
      label: {
        parent_directory: "部门名称",
        name: "名称",
        description: "描述",
        external_id: "外部ID"
      },
      placeholder: {
        name: "名称",
        description: "描述",
        external_id: "外部ID"
      },
      hint: {
        name: "请填写用户组名",
        description: "描述",
        external_id:
          "IAM中唯一标识，一般应填写原系统用户ID, 若不填将由系统自动生成。"
      }
    },
    department: {
      "Create Department": "新建部门",
      "Edit Department": "修改部门",
      external_id: "原系统用户ID",
      name: "名称",
      Search: "搜索",
      label: {
        parent_directory: "部门名称",
        name: "名称",
        description: "描述",
        external_id: "外部ID"
      },
      placeholder: {
        name: "请输入部门名称",
        description: "描述",
        external_id: "外部ID"
      },
      hint: {
        name: "请输入部门名称",
        description: "描述",
        external_id:
          "IAM中唯一标识，一般应填写原系统用户ID, 若不填将由系统自动生成。"
      }
    },
    application: {
      "Import SubApplication": "导入子账户",
      "Export SubApplication": "导出子账户",
      "Create Application": "添加应用",
      "Create SubApplication": "创建子账户",
      "Edit {type} adapter config": "编辑{type}应用",
      "See {type} adapter config": "查看{type}应用",
      "Create {type} adapter config": "创建{type}应用",
      "Create group config": "创建组站点",
      "Edit group config": "编辑组站点",
      "See group config": "查看组站点",
      "Edit {type} sub adapter config": "编辑{type}子账户",
      "See {type} sub adapter config": "查看{type}子账户",
      "Create {type} sub adapter config": "创建{type}子账户",
      "Edit sub group config": "编辑子站点",
      "See sub group config": "查看子站点",
      "Create sub group config": "创建子站点"
    },
    audit_log: {
      login: {
        username: "操作人",
        authMode: "认证方式",
        application_name: "应用名称",
        result: "操作结果",
        IP: "IP",
        time: "操作时间"
      }
    },
    accounts: {
      title: {
        id: "ID",
        loginname: "登录名称",
        fullname: "姓名",
        emailaddress: "邮箱",
        mobilephone: "手机号",
        accountEnabled: "开启状态",
        accountExpired: "过期时间"
      }
    },
    groups: {
      title: {
        id: "ID",
        name: "名称",
        external_id: "原系统用户组ID",
        description: "描述",
        source: "来源",
        department: "路径"
      }
    },
    license: {
      "Authorization File": "添加授权文件"
    },
    breadcrumb: {
      Dashboard: "管理后台",
      "Identity Source": "身份源管理",
      "Authentication Method": "认证方式管理",
      "Multi-Factor Login": "多因子认证",
      "Custom Login": "自定义登录",
      "Custom Details": "自定义登录详情",
      "Create Identity Source": "添加身份源",
      "Create Authentication Method": "添加认证方式",
      "SubApplication List": "子账户列表",
      "Link Company WeChat Authentication Source": "企业微信身份源配置",
      "Login Log": "登录日志",
      "Application Log": "应用访问日志",
      "Group Manage": "用户组管理",
      "User Manage": "用户管理",
      Organization: "组织机构",
      Accounts: "用户管理",
      "Account Details": "用户详情",
      Groups: "用户组管理",
      "Group Details": "用户组详情",
      "Attribute Manage": "属性管理",
      "Application List": "应用列表",
      "Create Application": "添加应用",
      "Site Management": "站点管理",
      "Personalization Settings": "个性化设置",
      "Authorization Information": "授权信息",
      "Security Settings": "安全设置",
      "Log Settings": "日志设置",
      WECHAT_ENTERPRISE: "企业微信身份源",
      WECHAT_WEBSITE: "微信网站身份源",
      WECHAT_OFFICIAL: "微信公众号身份源",
      OAUTH2: "OAUTH2身份源",
      CAS: "CAS身份源",
      CAS_REST_API: "CAS_REST_API身份源",
      DING_TALK: "钉钉身份源",
      LARK: "飞书身份源",
      LDAP: "LDAP身份源",
      FNU_LDAP: "FNU_LDAP身份源",
      WEBVPN: "WebVPN身份源",
      WebEXP: "WebEXP"
    },
    api: {
      null: "无",
      InvalidJsonResponse: "未能解析该API响应",
      UnableParseJsonResponse: "无法以JSON解析响应内容",
      UnableFetchResponse: "请求服务器失败",
      SourceNotFound: "未找到该企业身份源",
      InvalidPasswordLength: "无效密码长度",
      PasswordStrengthNotSatisfied: "密码强度不达标",
      UnexpectedException: "服务器出现了未知异常",
      InvalidExternalId: "externalId无效",
      ApplicationAlreadyArchived: "应用程序已存档",
      DuplicatedExternalId: "ExternalId重复，系统配置有误，请联系系统管理员",
      ApplicationNotFound: "找不到应用程序",
      AccountAlreadyArchived: "账户已存档",
      InvalidLoginName: "登录名无效",
      LoginNameAlreadyAssigned: "已分配登录名",
      LoginNameAlreadyOccupied: "登录名已被占用",
      DuplicatedAttributeDisplayName: "属性显示名称重复",
      DuplicatedAttributeName: "属性名重复",
      UniqueConstraintNotSatisfied: "不满足唯一约束",
      DepartmentParentNotFound: "找不到部门父级",
      RootDepartmentExisted: "根部门存在",
      DepartmentParentLoop: "部门父循环",
      DepartmentAlreadyArchived: "部门已存档",
      DescendantDepartmentExists: "后代部门已存在",
      DescendantAccountExists: "后代帐户已存在",
      DescendantGroupExists: "后代组已存在",
      GroupNameExistedInDepartment: "部门中已存在组名",
      GroupAlreadyArchived: "组已存档",
      AccountAlreadyAddedToGroup: "已将帐户添加到组中",
      AccountNotAddedToGroup: "帐户未添加到组中",
      AccountNotFound: "用户不存在",
      AttributeNotFound: "未找到属性",
      DepartmentNotFound: "未找到部门",
      GroupNotFound: "未找到组",
      DeletingBuiltinAttribute: "删除内置属性",
      EntityNotExist: "实体不存在",
      RequiredAttributeNotPresent: "必需属性不存在",
      AttributeNotExist: "属性不存在",
      UnrecognizedBooleanValue: "无法识别布尔值",
      ValueNotInOptions: "选项中不存在该值",
      UnrecognizedDatetimeValue: "无法识别日期时间值",
      UnrecognizedDateValue: "无法识别日期值",
      UnrecognizedValueType: "无法识别值类型",
      InvalidPassword: "密码无效",
      AdapterTypeNotImplemented: "适配器类型未实现",
      InvalidAdapterOptions: "适配器选项无效",
      ImmutableBuiltinIdentitySource: "不可变内置标识源",
      SourceAlreadyArchived: "源文件已存档",
      UnableArchiveEnabledSource: "无法启用存档的源",
      SourceEnabled: "启用源",
      IdentitySourceDisabled: "标识源已禁用",
      DuplicatedName: "名称重复",
      MismatchPassword: "原密码错误",
      CredentialNotFound: "未找到证书",
      PasswordNotFound: "未找到密码",
      PasswordExpired: "密码过期",
      InvalidFilterName: "过滤器名称无效",
      InsufficientPermission: "权限不足",
      SourceHasNoConnector: "请设置同步配置",
      SessionNotExists: "会话不存在",
      IdentityTokenExpired: "身份Token已过期",
      ExternalIdentityAlreadyBound: "外部身份已绑定",
      ExternalAlreadyBoundToCurrentAccount: "外部已绑定到当前帐户",
      UnableDecryptLicense: "license校验(解密)失败，可上传更新授权",
      LicenseNotPresent: "设备未授权，可上传授权",
      IncorrectPackageFormat: "授权文件有误， 可上传更新授权",
      IncorrectPayloadFormat: "有效负载格式不正确",
      InvalidSignature: "授权签名错误， 可上传更新授权",
      NoLicense: "暂未上传授权信息",
      AccountExpired: "用户已过期",
      UNEXPECTED_RESPONSE: "远程服务器返回错误响应",
      NETWORK_FAILURE: "网络故障,，请检查网络是否正常",
      INVALID_CREDENTIALS: "用户名或密码错误",
      UNKNOWN_ACCOUNT: "用户名或密码错误",
      SendSmsFailed: "发送短信失败",
      sms: {
        SourceNotFound: "认证方式不存在",
        AccountNotFound: "用户不存在",
        INVALID_CREDENTIALS: "用户名或密码错误",
        UNKNOWN_ACCOUNT: "用户名或密码错误",
        UNEXPECTED_RESPONSE: "远程服务器返回错误响应",
        EXPLICITLY_FORBIDDEN: "账户已锁定",
        SendSmsFailed: "发送短信失败"
      },
      EndpointUrlNotPresent: "请先在个性化设置页面中设置托管访问地址",
      InvalidEndpointUrl: "托管访问地址无效",
      SessionIdExpired: "该二维码已过期，请刷新页面后重新扫码",
      PasswordSourceNotEnabled: "密码登录未开启",
      AccountIdConflicted: "该微信号已绑定其他账号，请先解绑后再试",
      SubAccountAlreadyExisted: "子账户已存在",
      SubaccountAlreadyArchived: "子账户已删除",
      SubAccountNotExist: "子账户不存在",
      ServiceAlreadyRegistered: "服务已注册",
      StateNotChanged: "状态未改变",
      InvalidExcelData: "Excel数据无效",
      DuplicatedServiceId: "应用ID重复",
      SessionNameIsEmpty: "未登录",
      ClaimValueIsEmpty: "未设置用户别名的值",
      RequestTimeOut: "请求已过期",
      RecipientNotMatch: "Recipient不匹配",
      ReplayedRequest: "重复请求",
      NotSupported: "不支持此操作",
      EXPLICITLY_FORBIDDEN: "帐户已锁定",
      InvalidSessionId: "sessionId无效",
      InvalidCredential: "认证失败",
      InvalidCaptchaCode: "图形验证码无效",
      CaptchaNotMatched: "图形验证码不匹配",
      NotMatchWhitelist: "target url不在白名单内",
      CustomLoginNotEnabled: "自定义认证登录未开启",
      InvalidSyslogServer: "服务地址错误",
      AccountLocked: "帐户已锁定",
      LinkIsNotExist: "站点不存在",
      LinkIsNotActive: "站点未激活",
      MissingLinkId: "缺少站点ID",
      wechatqrcode: {
        INVALID_CONFIGURATION:
          "无法获取用户信息，请联系管理员确认配置是否正确。",
        INVALID_CREDENTIALS:
          "您没有权限访问本应用，如果您是该企业成员，请确认您是否关注了微信插件。"
      },
      wxworkqrcode: {
        INVALID_CONFIGURATION:
          "无法获取用户信息，请联系管理员确认配置是否正确。",
        INVALID_CREDENTIALS: "您没有权限访问本应用，请确认您选择了正确的企业。"
      }
    }
  }
};

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "zhHans", // set locale
  messages // set locale messages
});

export default i18n;
